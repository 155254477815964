import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../navigation";
import HeaderComponent from "../headerComponent";
import TextComponent from "../textComponent";
import RulerComponent from "../rulerComponent";
import ImageComponent from "../imageComponent";
import FooterComponent from "../footerComponent";
import BoxComponent from "../boxComponent";

const HomePage = () => {
	return (
		<div>
			<Navigation />
			<div class="mainBody">
				<div className="row">
					<div className="col-lg-12">
						{/*below is the HTML code for the logo animation*/}
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<div id="titleAnimation" style={{ textAlign: "center", fontSize: "18px", fontWeight: "300", height: "45px" }}>AbirStudio</div>
						</div>

						<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflow: "hidden", height: "24px" }}>
							<div id="one" style={{ zIndex: "3", position: "relative", backgroundColor: "#00bcd4", width: "33%", textAlign: "center" }}> Design </div>
							<div id="two" style={{ zIndex: "2", position: "relative", left: "-34%", top: "-24px", backgroundColor: "#b2ebf2", width: "34%", textAlign: "center" }}> Develop </div>
							<div id="three" style={{ zIndex: "1", position: "relative", left: "-33%", top: "-48px", backgroundColor: "#ff5722", width: "33%", textAlign: "center" }}> Deploy </div>
						</div>

					</div>
				</div>
				<div className="row">
					<div className="col-lg-8">
						<RulerComponent color="red" />
						<HeaderComponent text="Services:" />
						<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="2500" >
							<ol class="carousel-indicators">
								<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
								<li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
							</ol>
							<div class="carousel-inner" style={{ width: "97%", marginRight: "auto", marginLeft: "auto" }}>
								<div class="carousel-item active">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Custom CMS Design and Development" bgColor="#00bcd4" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Custom Logo Design" bgColor="#b2ebf2" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Mobile Application Design and Development" bgColor="#ff5722" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Search Engine Optimisation (SEO)" bgColor="#00bcd4" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Technical Consulting and Support" bgColor="#b2ebf2" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Website Design and Development" bgColor="#ff5722" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Website Management" bgColor="#00bcd4" />
								</div>
								<div class="carousel-item">
									<BoxComponent class="d-block w-100" isAnimationText={true} text="Windows Application Design and Development" bgColor="#b2ebf2" />
								</div>
							</div>
							<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span>
								<span class="sr-only">Previous</span>
							</a>
							<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span>
								<span class="sr-only">Next</span>
							</a>
						</div>
						<RulerComponent />
						<HeaderComponent text="I don't just develop software, I create unique experiences, that users will remember." />
						<RulerComponent />
						<p style={{ fontSize: "18px" }}>Beautiful, user-centric software and website solutions that will accelerate your business growth.<br />
						Click <Link to="/contact">here</Link> to get in touch with me today to find out how I can help you.</p>
					</div>
					<div className="col-lg-4">
						<ImageComponent id="picOfMe" src="me.png" />
						<TextComponent text="Hello my name is Abir Kazi. I am a freelance Full-Stack Developer with a Computer Science degree. I design and develop beautiful and user-centric software and website solutions which accelarate business reach and growth. My solutions are packed with leading edge technologies, offering your customers unique memorable user-experiences." />
					</div>
				</div>
				<RulerComponent />
				<HeaderComponent text="Technical expertise:" />
				<div className="row">
					<div className="col-lg-4">
						<BoxComponent text="AWS" id="firstThreeLangBox1" isLanguage={true} bgColor="#00bcd4" />
					</div>
					<div className="col-lg-4">
						<BoxComponent text="React" id="firstThreeLangBox2" isLanguage={true} bgColor="#00bcd4" />
					</div>
					<div className="col-lg-4">
						<BoxComponent text="Node.js" id="firstThreeLangBox3" isLanguage={true} bgColor="#00bcd4" />
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<BoxComponent text="JavaScript" isLanguage={true} bgColor="#b2ebf2" />
					</div>
					<div className="col-lg-4">
						<BoxComponent text="TypeScript" isLanguage={true} bgColor="#b2ebf2" />
					</div>
					<div className="col-lg-4">
						<BoxComponent text="HTML" isLanguage={true} bgColor="#b2ebf2" />
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<BoxComponent text="PHP" isLanguage={true} bgColor="#ff5722" />
					</div>
					<div className="col-lg-4">
						<BoxComponent text="Java" isLanguage={true} bgColor="#ff5722" />
					</div>
					<div className="col-lg-4">
						<BoxComponent text="C#" isLanguage={true} bgColor="#ff5722" />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<FooterComponent />
				</div>
			</div>
		</div>
	)
}

export default HomePage;