import React from "react";
import HeaderComponent from "../headerComponent";
import Navigation from "../navigation";
import TextComponent from "../textComponent";
import RulerComponent from "../rulerComponent";
import FooterComponent from "../footerComponent";

function AboutPage() {
    return (
        <div>
			<Navigation />
			<div class="mainBody">
				<div className="row">
					<div className="col-lg-12">
						{/*below is the HTML code for the logo animation*/}
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<div id="titleAnimation" style={{ textAlign: "center", fontSize: "18px", fontWeight: "300", height: "45px" }}>AbirStudio</div>
						</div>

						<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflow: "hidden", height: "24px" }}>
							<div id="one" style={{ zIndex: "3", position: "relative", backgroundColor: "#00bcd4", width: "33%", textAlign: "center" }}> Design </div>
							<div id="two" style={{ zIndex: "2", position: "relative", left: "-34%", top: "-24px", backgroundColor: "#b2ebf2", width: "34%", textAlign: "center" }}> Develop </div>
							<div id="three" style={{ zIndex: "1", position: "relative", left: "-33%", top: "-48px", backgroundColor: "#ff5722", width: "33%", textAlign: "center" }}> Deploy </div>
						</div>
					</div>
				</div>
				<HeaderComponent text="About Me" />
				<TextComponent text="I am a freelance designer and developer from the United Kingdom." />
				<RulerComponent color="red" />
				<TextComponent text="I develop custom software solutions both backend and frontend. I am experienced in enhancing existing websites and software solutions as well as developing something from a blank canvas." />
				<table>
					<tr>
						<th>
							<h4> <img src="https://img.icons8.com/ios-filled/50/000000/education.png" /> Education:</h4>
						</th>
					</tr>
					<tr>
						<td>
							I graduated with a First-Class Honours in BSc Computer Science from the University of Westminster.
			            </td>
					</tr>
				</table>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<FooterComponent />
				</div>
			</div>
		</div>
    )
}

export default AboutPage;