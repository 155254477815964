import React from "react";

function RulerComponent(props) {

	return (
		<hr
			style={{
				backgroundColor: props.color,
			}}
		/>
	)
}

export default RulerComponent;