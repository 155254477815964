import React from "react";
import Navigation from "./navigation";
import HeaderComponent from "./headerComponent";
import TextComponent from "./textComponent";
import ImageComponent from "./imageComponent";

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Link,
	Redirect
} from "react-router-dom";

//Pages 
import HomePage from "./pages/home";
import NotFoundPage from "./pages/404";
import AboutPage from "./pages/about";
import ProjectsPage from "./pages/projects";
import ContactPage from "./pages/contact";
import BlogPage from "./pages/blog";

function App() {
	return <Router>
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route exact path="/home" component={HomePage} />
			<Route exact path="/404" component={NotFoundPage} />
			<Route exact path="/about" component={AboutPage} />
			<Route exact path="/projects" component={ProjectsPage} />
			<Route exact path="/contact" component={ContactPage} />
			<Route exact path="/blog" component={BlogPage} />
			<Route exact path="/contactHandler.php" component={HomePage} />
			<Redirect to="/404" />
		</Switch>
	</Router>
}

export default App;