import React from "react";
import { Link, Route, Switch } from "react-router-dom";

import HomePage from "./pages/home";
import NotFoundPage from "./pages/404";
import AboutPage from "./pages/about";
import ProjectsPage from "./pages/projects";
import ContactPage from "./pages/contact";
import BlogPage from "./pages/blog";

function Navigation() {
	return (<nav className="navbar navbar-expand-lg navbar-light bg-light">
		<a className="navbar-brand" href="#">
			<img src="logo.png" style={{ width: "30px" }} />
		</a>
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>

		<div className="collapse navbar-collapse" id="navbarSupportedContent">
			<ul className="navbar-nav mr-auto">
				<li className="nav-item active">
					<Link className="nav-link" to="/home">Home <span className="sr-only">(current)</span></Link>
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/projects">Projects</Link>
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/blog">Blog</Link>
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/about">About</Link>
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/contact">Contact</Link>
				</li>
			</ul>
		</div>
	</nav>)
}

export default Navigation;