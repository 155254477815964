import React from 'react';
import ImageComponent from "./imageComponent";
import RulerComponent from "./rulerComponent";
import { Link } from "react-router-dom";

function FooterComponent() {

	return (
		<div className="footer">
			<div className="row" style={{ padding: "10px" }}>
				<div className="col-lg-4">
					<h5>
						About me:
				</h5>
					<p>I am an experienced freelance Full-Stack Developer with a Computer Science degree, who is passionate about designing and developing beautifull and user-centric software and website solutions which accelarate business reach and growth. My solutions are packed with leading edge technologies, offering your customers unique memorable user-experiences.</p>
					<p>Click <Link to="/about">here</Link> to find out more about me.</p>
				</div>
				<div className="col-lg-4">
					<h5>
						Services:
					</h5>
					<ul style={{ listStyleType: "square" }}>
						<li>Custom CMS Design and Development</li>
						<li>Custom Logo Design</li>
						<li>Mobile Application Design and Development</li>
						<li>Search Engine Optimisation (SEO)</li>
						<li>Technical Consulting and Support</li>
						<li>Website Design and Development</li>
						<li>Website Management</li>
						<li>Windows Application Design and Development</li>
					</ul>

				</div>
				<div className="col-lg-4">
					<h5>
						Contact:
				</h5>
					<ul style={{ listStyleType: "square" }}>
						<li><a href="mailto:contact@abirstudio.uk">contact@abirstudio.uk</a></li>
						<li><Link to="/contact">Visit contact page</Link></li>
					</ul>
				</div>

			</div>
			<RulerComponent color="white" />
			<div className="row">
				<div className="col-lg-12" id="footer">
					<ImageComponent class="socialIcon" src="../Linkedin_icon.png" width="50px" />
					<ImageComponent class="socialIcon" src="../github_icon.png" width="50px" />
					<ImageComponent class="socialIcon" src="../instagram_icon.png" width="47px" />
				</div>
			</div>
		</div>
	)
}

export default FooterComponent;