import React from "react";
import HeaderComponent from "../headerComponent";
import Navigation from "../navigation";
import TextComponent from "../textComponent";
import RulerComponent from "../rulerComponent";
import FooterComponent from "../footerComponent";
import BoxComponent from "../boxComponent";

function BlogPage() {
	return (<div>
		<Navigation />
		<div class="mainBody">
			<div className="row">
				<div className="col-lg-12">
					{/*below is the HTML code for the logo animation*/}
					<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
						<div id="titleAnimation" style={{ textAlign: "center", fontSize: "18px", fontWeight: "300", height: "45px" }}>AbirStudio</div>
					</div>

					<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflow: "hidden", height: "24px" }}>
						<div id="one" style={{ zIndex: "3", position: "relative", backgroundColor: "#00bcd4", width: "33%", textAlign: "center" }}> Design </div>
						<div id="two" style={{ zIndex: "2", position: "relative", left: "-34%", top: "-24px", backgroundColor: "#b2ebf2", width: "34%", textAlign: "center" }}> Develop </div>
						<div id="three" style={{ zIndex: "1", position: "relative", left: "-33%", top: "-48px", backgroundColor: "#ff5722", width: "33%", textAlign: "center" }}> Deploy </div>
					</div>

				</div>
			</div>
			<HeaderComponent text="Welcome to my Blog page" />
			<TextComponent text="Explore my hobbies and interests" />
			<RulerComponent color="red" />
			<BoxComponent isBlogArticle={true} title="Car based on Arduino" text="I enjoyed building my first smart car based on Arduino. This fun project taught me a lot of things. For one I learned a lot about micro-electronics, motors and I learned to appreciate the similarities and differences between software and hardware. For example, software unlike hardware does not have the kind of limitations physical electronics have. When you work with hardware you have physical limitations, like for example if you cut a wrong wire, you have to get a new wire, whereas with software you can undo mistakes. If you have made a mistake in your logic or made a simple syntax error, you can fix it without having to get a new computer. My little car had Ultrasonic sensor, Line Tracking module and an IR module, which you can individually configure and program for any spatial scenarios you can think of." imgSrc="elegooCar.jpg" imgWidth="98%" imgId="elegooCar" />
		</div>
		<div className="row">
			<div className="col-lg-12">
				<FooterComponent />
			</div>
		</div>
	</div>)
}

export default BlogPage;