import React from "react";

function PageNotFound() {
	return (
		<div>
			<h2 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>404 Error: Page not found</h2>
		</div>
	)
}

export default PageNotFound;