import React from 'react';
import ImageComponent from "./imageComponent";

function BoxComponent(props) {
    const isProject = props.isProject;
    const isBlogArticle = props.isBlogArticle;
    const imgSrc = props.imgSrc;
    const imgDescrip = props.imgDescrip;
    const isAnimationText = props.isAnimationText;
    const isLanguage = props.isLanguage;
    const link = props.link;
    const sourceCodeLink = props.sourceCodeLink;
    if (isLanguage) {
        return (
            <div className="langbox" id={props.id} style={{ backgroundColor: props.bgColor }}>
                <p>{props.text}</p>
            </div>
        )
    }
    if (isAnimationText) {
        return (
            <div className="animationBox" style={{ backgroundColor: props.bgColor }}>
                <p>{props.text}</p>
            </div>
        )
    }
    if (isBlogArticle) {
        return (
            <div className="blogArticle" style={{ backgroundColor: "#00bcd4", paddingBottom: "15px" }}>
                <h4 style={{ display: "inline", paddingLeft: "10px" }}>{props.title}</h4><br />
                <div className="row">
                    <div className="col-lg-4">
                        <ImageComponent src={props.imgSrc} descrip={props.imgDescrip} width={props.imgWidth} id={props.imgId} />
                    </div>
                    <div className="col-lg-8">
                        <p style={{ width: "96%", marginLeft: "auto", marginRight: "auto" }}> {props.text} </p>
                    </div>
                </div>
            </div>
        )
    } else {
        if (link) {
            if (sourceCodeLink) {
                return (
                    <div className="projbox">
                        <h4 style={{ display: "inline" }}>{props.title}</h4> <a href={sourceCodeLink} style={{ float: "right", marginLeft: "10px" }}>View Sourcecode<img src="https://img.icons8.com/material-rounded/20/000000/external-link.png" /></a><a href={link} style={{ float: "right" }}>Visit website<img src="https://img.icons8.com/material-rounded/20/000000/external-link.png" /></a>
                        <ImageComponent src={props.imgSrc} descrip={props.imgDescrip} width={props.imgWidth} />
                        <p>{props.text}</p>
                    </div>
                )
            } else {
                return (
                    <div className="projbox">
                        <h4 style={{ display: "inline" }}>{props.title}</h4> <a href={link} style={{ float: "right" }}>Visit website<img src="https://img.icons8.com/material-rounded/20/000000/external-link.png" /></a>
                        <ImageComponent src={props.imgSrc} descrip={props.imgDescrip} width={props.imgWidth} />
                        <p>{props.text}</p>
                    </div>
                )
            }

        } else {
            return (
                <div className="projbox">
                    <h4 style={{ display: "inline" }}>{props.title}</h4>
                    <ImageComponent src={props.imgSrc} descrip={props.imgDescrip} width={props.imgWidth} />
                    <p>{props.text}</p>
                </div>
            )
        }
    }
}

export default BoxComponent;