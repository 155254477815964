import React from "react";
import HeaderComponent from "../headerComponent";
import Navigation from "../navigation";
import FooterComponent from "../footerComponent";
import TextComponent from "../textComponent";
import RulerComponent from "../rulerComponent";

function ContactPage() {
	return (
		<div>
			<Navigation />
			<div class="mainBody">
				<div className="row">
					<div className="col-lg-12">
						{/*below is the HTML code for the logo animation*/}
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<div id="titleAnimation" style={{ textAlign: "center", fontSize: "18px", fontWeight: "300", height: "45px" }}>AbirStudio</div>
						</div>

						<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflow: "hidden", height: "24px" }}>
							<div id="one" style={{ zIndex: "3", position: "relative", backgroundColor: "#00bcd4", width: "33%", textAlign: "center" }}> Design </div>
							<div id="two" style={{ zIndex: "2", position: "relative", left: "-34%", top: "-24px", backgroundColor: "#b2ebf2", width: "34%", textAlign: "center" }}> Develop </div>
							<div id="three" style={{ zIndex: "1", position: "relative", left: "-33%", top: "-48px", backgroundColor: "#ff5722", width: "33%", textAlign: "center" }}> Deploy </div>
						</div>

					</div>
				</div>
				<HeaderComponent text="Contact me" />
				<TextComponent text="Contacting me is very easy. You can use the form below for any enquiries you may have." />
				<RulerComponent color="red" />
				<form id="contact-form" method="post" action="contactHandler.php">
					<div className="contactForm">
						<div>
							<h4>What type of business are you?</h4>
							<input type="radio" name="businessType" value="Individual" />
							<label>&nbsp;Individual</label><br />
							<input type="radio" name="businessType" value="Company" />
							<label>&nbsp;Company</label><br />
							<input type="radio" name="businessType" value="Agent" />
							<label>&nbsp;Agent</label><br />
						</div>
					</div>
					<RulerComponent />

					<div className="contactForm">
						<div>
							<h4>Contact Information: </h4>
							<label>Name:</label><br />
							<input type="text" name="name" placeholder="Your full name" />
						</div>
					</div>
					<div className="contactForm">
						<div>
							<label>Email:</label><br />
							<input type="email" name="email" placeholder="your@email.com" />
						</div>
					</div>
					<div className="contactForm">
						<div>
							<label>Phone number:</label><br />
							<input type="text" name="number" placeholder="Your phone number" />
						</div>
					</div>
					<div className="contactForm">
						<div>
							<label>Message:</label><br />
							<textarea id="messageTxtArea" name="message" placeholder="Please tell me what it is you want me to work on. Information such as budget, timeframe and what you want to achieve will be very helpfull."></textarea>
						</div>
					</div>
					<RulerComponent />
					<div className="contactForm">
						<div>
							<h4>What is your budget?</h4>
							<input type="radio" name="budget" value="£500 - £1000" />
							<label>&nbsp;£500 - £1000</label><br />
							<input type="radio" name="budget" value="£1000 - £2000" />
							<label>&nbsp;£1000 - £2000</label><br />
							<input type="radio" name="budget" value="£2000 - £3000" />
							<label>&nbsp;£2000 - £3000</label><br />
							<input type="radio" name="budget" value="£3000 - £4000" />
							<label>&nbsp;£3000 - £4000</label><br />
							<input type="radio" name="budget" value="£5000+" />
							<label>&nbsp;£5000+</label><br />
						</div>
					</div>
					<div className="contactForm">
						<div>
							<input type="submit" value="Contact me" />
						</div>
					</div>
				</form>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<FooterComponent />
				</div>
			</div>
		</div>
	)
}

export default ContactPage;