import React from "react";
import HeaderComponent from "../headerComponent";
import Navigation from "../navigation";
import TextComponent from "../textComponent";
import RulerComponent from "../rulerComponent";
import FooterComponent from "../footerComponent";
import BoxComponent from "../boxComponent";

function ProjectsPage() {
    return (
        <div>
			<Navigation />
			<div class="mainBody">
				<div className="row">
					<div className="col-lg-12">
						{/*below is the HTML code for the logo animation*/}
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<div id="titleAnimation" style={{ textAlign: "center", fontSize: "18px", fontWeight: "300", height: "45px" }}>AbirStudio</div>
						</div>

						<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", overflow: "hidden", height: "24px" }}>
							<div id="one" style={{ zIndex: "3", position: "relative", backgroundColor: "#00bcd4", width: "33%", textAlign: "center" }}> Design </div>
							<div id="two" style={{ zIndex: "2", position: "relative", left: "-34%", top: "-24px", backgroundColor: "#b2ebf2", width: "34%", textAlign: "center" }}> Develop </div>
							<div id="three" style={{ zIndex: "1", position: "relative", left: "-33%", top: "-48px", backgroundColor: "#ff5722", width: "33%", textAlign: "center" }}> Deploy </div>
						</div>

					</div>
				</div>
				<HeaderComponent text="Every client has unique requirements, so every project is unique." />
				<TextComponent text="Explore my latest projects." />
				<RulerComponent color="red" />
				<div className="row">
					<div className="col-lg-6">
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<BoxComponent isProject={true} title="SyberSystems Transformation" text="SyberSystems is technology and software company. Their outdated website was replaced by a complete new website design, which does an excellent job reflecting their work." imgSrc="sybersystems.png" imgWidth="100%" link="http://www.sybersystems.co.uk/sybersystems.html" />
						</div>
					</div>
					<div className="col-lg-6">
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<BoxComponent isProject={true} title="Code Visualiser CMS" text="Code Visualiser is a learning platform for programming for which I developed a custom bespoke Content Management System(CMS) using CodeIgniter, a powerfull PHP MVC Framework." imgSrc="learntocode.png" imgWidth="100%" link="http://learntocode.westminster.ac.uk/codeVisAlpha.php" sourceCodeLink="https://github.com/AbirKazi/CMS-Solution-for-learntocode.westminster.ac.uk/blob/master/for.html" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
							<BoxComponent isProject={true} title="EastLux Bespoke Logo Design" text="I designed a bespoke and elegant looking logo that does justice for a luxury car hiring brand like EastLux." imgSrc="EastLux_Logo1.png" imgWidth="100%" />
						</div>
					</div>
					<div className="col-lg-6">
						<div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
						    {/* using isLanguage BoxComponent to display centered text below */}
							<BoxComponent text="More exciting projects coming up soon!" isLanguage={true} />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<FooterComponent />
				</div>
			</div>
		</div>
    )
}

export default ProjectsPage;