import React from "react";


function ImageComponent(props) {
	return (
		<img id={props.id} className={props.class} src={props.src} alt={props.descrip} width={props.width} />
	)
}

export default ImageComponent;
